<template>
    <div>
        <w-toolbar shadow class="mt0 py1">
            <w-icon xl color="primary" @click="openDrawer = true" style="cursor: pointer;">
                fa fa-bars
            </w-icon>
            <w-image class="ml5" :src="require('@/assets/logo.png')" height="40" width="40">
            </w-image>
            <div class="title3 mx3">Administración y Gestión de Negocios</div>
            <div class="spacer"></div>
            <w-menu v-if="$waveui.breakpoint.name == 'lg' || $waveui.breakpoint.name == 'xl'">
                <template #activator="{ on }">
                    <w-button v-on="on" class="mr2" bg-color="success" icon="wi-check"></w-button>
                </template>
                <span @click="logout">
                    Cerrar Sesion
                </span>

            </w-menu>
            <div class="title4 mr1" v-if="dsUser && $waveui.breakpoint.name == 'lg' || $waveui.breakpoint.name == 'xl'">{{
                dsUser.name }} <br> <strong>{{ dsUser.rol.description }}</strong></div>

            <w-badge class="mr3" bg-color="error">
                <template #badge>3</template>
                <w-button icon="fa fa-bell" text lg></w-button>
            </w-badge>
        </w-toolbar>
        <w-drawer v-model="openDrawer" left :no-overlay="noOverlay" :overlay-color="overlayColor" :width="widthBP"
            :responsive="{ sm: true }">
            <w-button @click="openDrawer = false" sm outline absolute round icon="wi-cross">
            </w-button>
            <w-image class="mt10" :src="require('@/assets/banners/eficent-drawer.jpg')" width="100%" height="100">
            </w-image>
            <w-list v-model="selection" :items="menuDrawer" color="blue-dark1" selection-color="orange-dark1"
                :multiple="false" @item-click="itemClicked(selection)" class="mt2 grow">
            </w-list>
        </w-drawer>
        <w-breadcrumbs :items="urls" class="pa2 red--color-bg">
            <template #item="{ item, isLast }">
                <w-tag :color="isLast ? 'cyan-dark1' : 'orange-dark1'" round outline v-html="item.text">
                </w-tag>
            </template>
        </w-breadcrumbs>
        <router-view />
    </div>
</template>
<script>

import 'font-awesome/css/font-awesome.min.css'
import { useCommonStore } from '@/stores/commons.js'
import { useAccountStore } from '@/stores/accounts'
import { isAuthorized } from '@/utils/validate'
import { useMessagingStore } from '@/stores/messaging'

export default {
    data: () => ({
        // dsUser: null,
        // valAccess: isAuthorized,
        openDrawer: false,
        noOverlay: false,
        overlayColor: '',
        mDrawer: [
            { label: 'Inicio', value: '/', roles: ['*'] },
            { label: 'Administración', value: 'administracion', roles: ['efs_admin', 'bcs_ceo', 'usr_finance'] },
            { label: 'Facturación', value: 'facturas', roles: ['efs_admin', 'bcs_ceo', 'management_invoice', 'usr_finance', 'usr_accounting', 'usr_invoice'] },
            { label: 'Dispersión', value: 'disperciones', roles: ['efs_admin', 'bcs_ceo', 'usr_finance'] },
            { label: 'Materialización', value: 'materializacion', roles: ['efs_admin', 'bcs_ceo', 'usr_materialization'] },
            { label: 'Catalogos', value: 'catalogos', roles: ['efs_admin', 'bcs_ceo', 'management_invoice', 'usr_attendance', 'usr_invoice'] },
            { label: 'Requisiciones', value: 'requisiciones', roles: ['efs_admin', 'bcs_ceo', 'usr_finance','management_invoice', 'usr_accounting', 'usr_invoice', 'usr_materialization', 'usr_legal', 'usr_bc_group'] },
            { label: 'Requisicion obras', value: 'obras', roles: ['efs_admin', 'usr_finance', 'bcs_ceo', 'usr_obras', 'usr_qry_obras'] },
            { label: 'Requisicion Eudania', value: 'eudania', roles: ['efs_admin', 'usr_finance', 'bcs_ceo', 'usr_eudania'] },
            { label: 'Documentos', value: 'gestion_documental', roles: ['efs_admin', 'bcs_ceo', 'management_invoice', 'usr_dispersion', 'usr_accounting', 'usr_invoice', 'usr_dispersion', 'usr_finance', 'usr_obras', 'usr_legal'] },
            { label: "Cerrar Sesion", value: "logout", roles: ['*'] }
        ],
        selection: '/',
        itemNav: '/',
    }),
    computed: {
        menuDrawer() {
            let a = []
            this.mDrawer.forEach(element => {
                if (isAuthorized(element.roles)) {
                    a.push(element)
                }
            });
            return a
        },
        urls() {
            const store = useCommonStore();
            return store.items
        },
        dsUser() {
            const Ustore = useAccountStore()
            let u = Ustore.userAccount
            return u
        },
        widthBP() {
            let w = '50%'
            switch (this.$waveui.breakpoint.name) {
                case 'xs':
                    w = '85%'
                    break;
                case 'sm':
                    w = '45%'
                    break;
                case 'md':
                    w = '45%'
                    break;
                case 'lg':
                    w = '20%'
                    break;
                case 'xl':
                    w = '20%'
                    break;
            }
            return w
        }
    },
    methods: {
        itemClicked(item) {
            this.$router.push({ path: `/${this.selection}` })
            this.selection = null
            this.openDrawer = false
        },
        getDataUser() {
            const Ustore = useAccountStore()
            this.dsUser = Ustore.userAccount
        },
        logout() {
            const Ustore = useAccountStore()
            const fmcStore = useMessagingStore();
            try {
                fmcStore.unsuscribe_to_group().then(() => {
                    Ustore.logout()
                    this.$router.replace('/login')
                },()=>{
                    Ustore.logout()
                    this.$router.replace('/login')
                })
            } catch (error) {
                console.error(error)
                Ustore.logout()
                this.$router.replace('/login')
            }


        }
    }
}
</script>
<style lang="css" scope>
.w-drawer {
    display: flex;
    flex-direction: column;
}
</style>